var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"model-info-modal","tabindex":"-1","role":"dialog","aria-labelledby":"model-info-modal","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.__('model.model.name')))+": "+_vm._s(_vm.theModel.name)+" ("+_vm._s(_vm.theModel.number)+")")]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('form',{attrs:{"id":"model-form"},on:{"submit":function($event){$event.preventDefault();return _vm.save('model-form', _vm.theModel.id)}}},[_c('div',{staticClass:"modal-body"},[_c('label',[_vm._v(_vm._s(_vm.__('model.model.attr.epc_cover_page_plural')))]),_vm._v(" "),_c('div',{staticClass:"d-grid gap-3",staticStyle:{"grid-template-columns":"repeat(3, 1fr)"}},_vm._l((_vm.theModel.epc_cover_pages),function(epcCoverPage){return _c('div',{staticClass:"form-group"},[(epcCoverPage.id > 0)?[_c('label',[_vm._v(_vm._s(epcCoverPage.filename))]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('div',{directives:[{name:"viewer",rawName:"v-viewer",value:({
                                                 fullscreen: false,
                                                 next: false,
                                                 rotatable: false,
                                                 scalable: false,
                                                 zoomRatio: 0.25,
                                                 tooltip: false,
                                            }),expression:"{\n                                                 fullscreen: false,\n                                                 next: false,\n                                                 rotatable: false,\n                                                 scalable: false,\n                                                 zoomRatio: 0.25,\n                                                 tooltip: false,\n                                            }"}]},[_c('img',{staticClass:"w-100 hover-pointer",attrs:{"src":epcCoverPage.url}})])]),_vm._v(" "),_c('div',{staticClass:"col-4"},[_c('confirm-buttons',{on:{"confirmed":function($event){return _vm.deleteEpcCoverPage(epcCoverPage.id)}}})],1)])]:[_c('label',{attrs:{"for":("epc-cover-page-" + (epcCoverPage.id))}},[_vm._v(_vm._s(_vm.__('model.model.attr.epc_cover_page')))]),_vm._v(" "),_c('input',{staticClass:"form-control",attrs:{"type":"file","id":("epc-cover-page-" + (epcCoverPage.id)),"name":"epc_cover_pages[]","accept":"image/jpeg,image/png","disabled":_vm.globalVars.isLoading,"required":""}})]],2)}),0),_vm._v(" "),_c('button',{staticClass:"btn btn-success btn-sm",attrs:{"type":"button"},on:{"click":_vm.addEpcCoverPage}},[_c('i',{staticClass:"fas fa-plus"})])]),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('error-message',{attrs:{"error":_vm.error,"timeout":true,"classes":"mt-3"}}),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.globalVars.isLoading}},[_vm._v("\n                        "+_vm._s(_vm._f("capitalize")(_vm.__('button.save', { model: _vm.__('model.model.name') })))+" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.globalVars.isLoading),expression:"globalVars.isLoading"}],staticClass:"fas fa-circle-notch fa-spin"})])],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])}]

export { render, staticRenderFns }