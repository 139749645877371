<template>
    <div class="modal fade" id="model-info-modal" tabindex="-1" role="dialog" aria-labelledby="model-info-modal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ __('model.model.name') | capitalize }}: {{ theModel.name }} ({{ theModel.number }})</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form id="model-form" @submit.prevent="save('model-form', theModel.id)">
                    <div class="modal-body">
                        <label>{{ __('model.model.attr.epc_cover_page_plural') }}</label>

                        <div class="d-grid gap-3" style="grid-template-columns: repeat(3, 1fr);">
                            <div v-for="epcCoverPage of theModel.epc_cover_pages" class="form-group">
                                <template v-if="epcCoverPage.id > 0">
                                    <label>{{ epcCoverPage.filename }}</label>
                                    <div class="row">
                                        <div class="col-8">
                                            <div
                                                v-viewer="{
                                                     fullscreen: false,
                                                     next: false,
                                                     rotatable: false,
                                                     scalable: false,
                                                     zoomRatio: 0.25,
                                                     tooltip: false,
                                                }">
                                                <img :src="epcCoverPage.url" class="w-100 hover-pointer">
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <confirm-buttons @confirmed="deleteEpcCoverPage(epcCoverPage.id)"></confirm-buttons>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <label :for="`epc-cover-page-${epcCoverPage.id}`">{{ __('model.model.attr.epc_cover_page') }}</label>
                                    <input type="file" :id="`epc-cover-page-${epcCoverPage.id}`" class="form-control" name="epc_cover_pages[]" accept="image/jpeg,image/png" :disabled="globalVars.isLoading" required>
                                </template>
                            </div>
                        </div>

                        <button type="button" class="btn btn-success btn-sm" @click="addEpcCoverPage"><i class="fas fa-plus"></i></button>
                    </div>
                    <div class="modal-footer">
                        <error-message :error="error" :timeout="true" classes="mt-3"></error-message>

                        <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">
                            {{ __('button.save', { model: __('model.model.name') }) | capitalize }} <i v-show="globalVars.isLoading" class="fas fa-circle-notch fa-spin"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorMessage from '../ErrorMessage';
    import ConfirmButtons from '../util/ConfirmButtons';

    export default {
        components: {
            ErrorMessage,
            ConfirmButtons,
        },
        props: {
            model: Object,
        },
        data() {
            return {
                error: new _Error(),
                theModel: new Model(),
            };
        },
        watch: {
            model: function () {
                this.theModel = this.model;
            },
        },
        methods: {
            save(formID, modelId) {
                if (modelId > 0) {
                    let formData = new FormData(document.getElementById(formID));

                    axios({
                        method: 'post',
                        url: `/api/models/${modelId}`,
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                        .then(response => {
                            this.error = response.data.error;

                            this.resetForm('model-form');

                            this.theModel = response.data.result;
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        });
                }
            },
            addEpcCoverPage() {
                this.theModel.epc_cover_pages.push(new EpcCoverPage(this.descendingId()));
            },
            deleteEpcCoverPage(id) {
                if (id > 0) {
                    axios.delete(`/api/epc-cover-pages/${id}`)
                        .then(response => {
                            this.error = response.data.error;

                            this.theModel.epc_cover_pages = this.theModel.epc_cover_pages.filter(epcCoverPage => epcCoverPage.id !== id);
                        });
                } else {
                    this.theModel.epc_cover_pages = this.theModel.epc_cover_pages.filter(epcCoverPage => epcCoverPage.id !== id);
                }
            },
        },
    };
</script>
